import { render, staticRenderFns } from "./CustomerInfo.vue?vue&type=template&id=7de31d07&scoped=true&"
import script from "./CustomerInfo.vue?vue&type=script&lang=js&"
export * from "./CustomerInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7de31d07",
  null
  
)

export default component.exports