<template>
  <InvoiceForm />
</template>
<script>
import InvoiceForm from '@/components/sales-store/InvoiceForm.vue'

export default {
  name: 'Form',
  components: { InvoiceForm },
}
</script>

<style scoped lang="scss">

</style>
