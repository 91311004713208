<template>
  <b-sidebar
    id="add-new-user"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add user
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="invoiceValidation">
        <b-form
          class="p-2"
          @submit.prevent
        >

          <!-- user Name -->
          <b-form-group
            label="First Name"
            label-for="user-name"
          >
            <validation-provider
              #default="{ errors }"
              name="First Name"
              rules="required"
            >
              <b-form-input
                id="user-first-name"
                v-model="user.first_name"
                :state="errors.length > 0 ? false:null"
                trim
                placeholder="John"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Last Name"
            label-for="user-name"
          >
            <validation-provider
              #default="{ errors }"
              name="Last Name"
              rules="required"
            >
              <b-form-input
                id="user-last-name"
                v-model="user.last_name"
                :state="errors.length > 0 ? false:null"
                trim
                placeholder="Doe"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Email -->
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="user.email"
              trim
              placeholder="example@domain.com"
              type="email"
            />
          </b-form-group>
          <!-- Contact -->
          <b-form-group
            label="Contact"
            label-for="contact"
          >
            <validation-provider
              #default="{ errors }"
              name="Phone"
              rules="required"
            >
              <b-input-group
                label="Phone"
                label-for="phone"
              >
                <b-form-input
                  id="contact"
                  v-model="user.phone"
                  :state="errors.length > 0 ? false:null"
                  placeholder="8801xxxxxxxx"
                  trim
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="addUser"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'CustomerInfo',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        phone_code: '880',
      },
    }
  },
  methods: {
    addUser() {
      this.$refs.invoiceValidation.validate().then(success => {
        if (success) {
          this.user.email = (this.user.email).toLowerCase()
          this.$emit('add-user', this.user)
          this.$root.$emit('bv::toggle::collapse', 'add-new-user')
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
